/********** Template CSS **********/
:root {
  --primary: #fda12b;
  --secondary: #8d9297;
  --light: #f8f9fa;
  --dark: #182333;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: 0.5s;
}

.navbar .navbar-nav .nav-link {
  margin-left: 30px;
  padding: 25px 0;
  color: var(--secondary);
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-left: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    margin-top: 25px;
    border-top: 1px solid #eeeeee;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: start;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
  background-color: var(--dark);
  border: 15px solid var(--dark);
  border-radius: 50px;
}

.carousel-caption .breadcrumb-item + .breadcrumb-item::before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: var(--primary);
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    border-width: 12px;
  }
}

.page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url(./assets/image/main/carousel-1.jpeg) center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: #999999;
}

/*** Facts ***/
.facts-overlay {
  position: absolute;
  padding: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.65);
}

.facts-overlay h1 {
  font-size: 120px;
  color: transparent;
  -webkit-text-stroke: 2px var(--primary);
}

.facts-overlay a:hover {
  color: var(--primary) !important;
}

/*** Service ***/
.service-text::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: var(--primary);
  opacity: 0;
  transition: 0.5s;
}

.service-item:hover .service-text::before {
  height: 100%;
  opacity: 1;
}

.service-text * {
  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.service-item:hover .service-text * {
  color: #ffffff !important;
}

/*** Appointment ***/
.appointment {
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url(./assets/image/main/carousel-1.jpeg) center center no-repeat;
  background-size: cover;
}

/*** Team ***/
.team-text {
  position: absolute;
  width: 75%;
  bottom: 30px;
  left: 0;
  transition: 0.5s;
}

.team-item:hover .team-text {
  width: 100%;
}

.team-text * {
  transition: 0.5s;
}

.team-item:hover .team-text * {
  letter-spacing: 2px;
}

/*** Testimonial ***/
.testimonial-carousel .owl-item img {
  width: 100px;
  height: 100px;
}

.testimonial-carousel .owl-nav {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin-right: 15px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  border: 2px solid var(--primary);
  border-radius: 50px;
  font-size: 18px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: #ffffff;
  background: var(--primary);
}

/*** Footer ***/
.footer {
  color: #999999;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #999999;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #999999;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .copyright a:hover {
  color: var(--primary);
}
